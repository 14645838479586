import { useState, useEffect } from 'react'

const url = 'https://api.lever.co/v0/postings/envato-2?mode=json'

export const useFetchRoles = () => {
  const [state, setState] = useState({ openPositions: [], loading: true, error: false })

  useEffect(() => {
    window
      .fetch(url)
      .then((response) => response.json())
      .then((openPositions) => {
        setState(() => ({ openPositions, loading: false, error: false }))
      })
      .catch((e) => {
        setState((state) => ({ ...state, error: true, loading: false }))
        console.error('Error fetching roles: ', e)
      })
  }, [])

  return state
}
