import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '../../i18n/index'
import Figma from '../figma/figma'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import Hyperlink from '../hyperlink/hyperlink'
import translations, { locations } from './open_positions.translations'
import styles from './open_positions.module.css'
import Select from '../select/select'
import { useFetchRoles } from './use_fetch_roles'

const OpenPositions = ({ location }) => {
  const { openPositions, loading, error } = useFetchRoles()
  const t = useTranslation(translations)
  const [filter, setFilter] = useState(location)
  const positionsToDisplay =
    filter === locations.all
      ? openPositions
      : openPositions.filter((position) => filter === position.categories.location)

  return (
    <Figma.Section background='grey40'>
      <CenteredSectionWrapper>
        <div className={styles.body}>
          <h2>
            <Figma.Text type='heading'>{t.heading}</Figma.Text>
          </h2>
          <div className={styles.filter}>
            <Select label='Year' items={Object.values(locations)} value={filter} onChange={setFilter} />
          </div>
          {error && !loading ? <p className={styles.noOpenPositions}>{t.errorMessage}</p> : null}
          {!positionsToDisplay.length && !loading && !error ? (
            <p className={styles.noOpenPositions}>{t.noPositionsMessage[filter]}</p>
          ) : null}
          {positionsToDisplay.length > 0 && !loading && !error ? (
            <ul className={styles.list}>
              {positionsToDisplay.map((position, i) => (
                <li key={`${position.title}${i}`} className={styles.li}>
                  <h2>
                    <Hyperlink className={styles.link} href={position.hostedUrl}>
                      {position.text}
                    </Hyperlink>
                  </h2>
                  <p className={styles.positionDescription}>{position.categories.location}</p>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </CenteredSectionWrapper>
    </Figma.Section>
  )
}

OpenPositions.propTypes = {
  openPositions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      absolute_url: PropTypes.string.isRequired,
      location: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  location: PropTypes.oneOf(Object.values(locations))
}

export default OpenPositions
