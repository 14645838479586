import React from 'react'
import PropTypes from 'prop-types'
import styles from './select.module.css'

export default function Select({ label, items, value, onChange }) {
  return (
    <select className={styles.root} aria-label={label} onChange={(e) => onChange(e.target.value)} value={value}>
      {items.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  )
}

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}
